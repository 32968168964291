<template>
  <section class="choose-language">
    <div class="label">{{ $t('language.title') }}</div>
    <a-select
      v-model="lang"
      class="language"
      :loading="loading"
      dropdown-class-name="custom-select-dropdown"
      :get-popup-container="getPopupContainer"
      @change="handleQueryLang"
    >
      <a-select-option v-for="(value, key) in LanguageList" :key="key">
        {{ value }}
      </a-select-option>
    </a-select>
  </section>
</template>
<script>
import { LanguageList } from './config';
import { setDefaultLanguage, localeList, defaultLocale } from '@/locales/index';
import { getRegion, getCookieLang } from '@/libs/index';

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Language',
    },
  },
  data() {
    return {
      lang: defaultLocale,
      LanguageList,
    };
  },
  mounted() {
    const routerLang = this.$route.query.lang;
    let lang =
      this.$route.query.lang ||
      getCookieLang() ||
      defaultLocale ||
      this.$store.getters.getCountryByName(getRegion()).lang;
    if (!localeList.includes(lang)) {
      lang = defaultLocale;
    }
    this.lang = lang;
    setDefaultLanguage(lang);
    if (!routerLang) {
      this.$router.replace({
        query: { ...this.$route.query, lang },
      });
    }
  },
  methods: {
    async handleQueryLang(value) {
      await setDefaultLanguage(value);
      this.$router.replace({
        query: { ...this.$route.query, lang: value },
      });
    },
    getPopupContainer() {
      return document.querySelector('.choose-language');
    },
  },
};
</script>
<style lang="less" scoped>
.choose-language {
  display: flex;
  flex-direction: row;
  align-items: center;

  font-size: 14px;

  > .label {
    margin-right: 12px;
  }
  > .language {
    min-width: 130px;
    /deep/.ant-select-selection {
      border-radius: 10px;
      box-shadow: none;
    }
  }
  ::v-deep {
    .ant-select-dropdown-menu {
      max-height: 280px;
    }
  }
}
@media (max-width: 640px) {
  .choose-language {
    > .label {
      display: none;
    }
  }
}
</style>
