<template>
  <div class="payment">
    <Header></Header>
    <div id="main" class="groups">
      <div v-for="(group, index) in groups" :key="index" class="group">
        <div class="title">{{ group.title }}:</div>
        <banner
          v-for="(content, i) in group.contents"
          :key="i"
          :content="content"
          @handle-click="handleBannerClick(content)"
        ></banner>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from './Header.vue';
import Footer from './Footer.vue';
import Banner from '../components/Banner';

import { isTw } from '@/constants/env';

export default {
  name: 'Payment',
  components: {
    Header,
    Footer,
    Banner,
  },
  computed: {
    groups() {
      let region = '';
      if (isTw) {
        // 台湾地区，直接显示支付方式
        region = 'TW';
      }
      const { country } = this.$route.query;
      const arr = this.$store.getters.getGroups(country || region);
      return arr;
    },
  },
  methods: {
    handleBannerClick({ isCountry, query }) {
      let name = 'order',
        queryNew = { ...this.$route.query, ...query };
      if (isCountry) {
        name = 'dpurchase';
      }
      this.$router.push({ name, query: queryNew });
    },
  },
};
</script>

<style lang="less" scoped>
.payment {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}
.groups {
  flex-grow: 1;
  max-width: 840px;
  margin: 0 auto 60px;
  .group {
    display: flex;
    flex-flow: wrap;
    margin-top: 60px;
    .title {
      width: 100%;
      line-height: 1;
      font-size: 18px;
    }
    .banner {
      margin-right: 24px;
      margin-top: 24px;
    }
  }
}
@media (max-width: 850px) {
  .groups {
    max-width: 640px;
    padding: 0 12px;
    margin-bottom: 36px;
    .group {
      margin-top: 30px;
      .title {
        font-size: 16px;
        line-height: 30px;
      }
      .banner {
        margin-right: 0;
        margin-top: 12px;
      }
    }
  }
}
</style>
