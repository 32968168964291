import Vue from 'vue';
import router from './router';
import store from './store';
import VueClipboard from 'vue-clipboard2';
import {
  Button,
  FormModel,
  Input,
  Spin,
  Select,
  Checkbox,
  Alert,
  Icon,
  Dropdown,
  Menu,
} from 'ant-design-vue';
import App from './App.vue';
import i18n from './locales';

Vue.use(Button);
Vue.use(FormModel);
Vue.use(Input);
Vue.use(Spin);
Vue.use(Select);
Vue.use(Checkbox);
Vue.use(Alert);
Vue.use(Icon);
Vue.use(Dropdown);
Vue.use(Menu);

Vue.use(VueClipboard);

Vue.config.productionTip = false;

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
