const Payments = {
  namespaced: true,
  state: {
    // 支付方式合集
    payment_lib: {
      // name: 支付方式名称
      // icon: 支付方式角标
      PayPal: {
        name: 'PayPal',
        icon: '',
      },
      Razer: {
        name: 'Razer',
        icon: 'ico_razer.png',
      },
      Codapay: {
        name: 'Codapay',
        icon: 'ico_codapay.png',
      },
      UniPin: {
        name: 'UniPin',
        icon: 'ico_uni_pin.png',
      },
      Toss: {
        name: 'Toss',
        icon: 'ico_toss.png',
      },
    },
    // 支付渠道合集
    channel_lib: {
      pay_pal: {
        key: 'pay_pal', // 主键
        name: 'pay_pal', // 渠道名称（用于后端传输）
        text: 'PayPal', // 渠道名称展示
        logo: 'pay_pal.png', // 渠道 logo
        payment: 'PayPal', // 支付方式
        showIcon: false, // 是否显示右上角“支付方式角标”
      },
      razer_gold_pin: {
        key: 'razer_gold_pin',
        name: 'razer_gold_pin',
        text: 'Razer Gold PIN',
        logo: 'razer_pin.png',
        payment: 'Razer',
        showIcon: true,
      },
      true_money: {
        key: 'true_money',
        name: 'true_money',
        text: 'TrueMoney',
        logo: 'true_money.png',
        payment: 'Razer',
        showIcon: true,
      },
      ais_12_call: {
        key: 'ais_12_call',
        name: 'ais_12_call',
        text: '12Call',
        logo: '12_call.png',
        payment: 'Razer',
        showIcon: true,
      },
      true_wallet: {
        key: 'true_wallet',
        name: 'true_wallet',
        text: 'TrueMoney Wallet',
        logo: 'true_money_wallet.png',
        payment: 'Razer',
        showIcon: true,
      },
      m_pay: {
        key: 'm_pay',
        name: 'm_pay',
        text: 'mPay',
        logo: 'm_pay.png',
        payment: 'Razer',
        showIcon: true,
      },
      rabbit_Line_Pay: {
        key: 'rabbit_Line_Pay',
        name: 'rabbit_line_pay',
        text: 'Rabbit LINE Pay',
        logo: 'rabbit_line_pay.png',
        payment: 'Codapay',
        showIcon: true,
      },
      e_wallet: {
        key: 'e_wallet',
        name: 'e_wallet',
        text: 'Razer Gold Wallet',
        logo: 'e_wallet.png',
        payment: 'Razer',
        showIcon: false,
      },
      dtac: {
        key: 'dtac',
        name: 'dtac',
        text: 'DTAC',
        logo: 'dtac.png',
        payment: 'Razer',
        showIcon: true,
      },
      ais: {
        key: 'ais',
        name: 'ais',
        text: 'AIS',
        logo: 'ais.png',
        payment: 'Razer',
        showIcon: true,
      },
      true_move: {
        key: 'true_move',
        name: 'true_move',
        text: 'TRUE',
        logo: 'true.png',
        payment: 'Razer',
        showIcon: true,
      },
      bank_transfer_and_counter_payment: {
        key: 'bank_transfer_and_counter_payment',
        name: 'bank_transfer_and_counter_payment',
        text: 'Bank transfer',
        logo: 'bank_transfer_and_counter_payment.png',
        payment: 'Codapay',
        showIcon: true,
      },
      uni_pin: {
        key: 'uni_pin',
        name: 'uni_pin',
        text: 'UniPin',
        logo: 'uni_pin.png',
        payment: 'UniPin',
        showIcon: false,
      },
      atm_m_bank_l_bank: {
        key: 'atm_m_bank_l_bank',
        name: 'atm_m_bank_l_bank',
        text: 'ATM/M-BANK/I-BANK',
        logo: 'atm_m_bank_l_bank.png',
        payment: 'UniPin',
        showIcon: true,
      },
      bayar_di_alfa: {
        key: 'bayar_di_alfa',
        name: 'bayar_di_alfa',
        text: 'Bayar di Alfa',
        logo: 'bayar_di_alfa.jpg',
        payment: 'UniPin',
        showIcon: true,
      },
      indomaret: {
        key: 'indomaret',
        name: 'indomaret',
        text: 'Indomaret',
        logo: 'indomaret.png',
        payment: 'UniPin',
        showIcon: true,
      },
      go_pay: {
        key: 'go_pay',
        name: 'go_pay',
        text: 'GO-PAY',
        logo: 'go_pay.png',
        payment: 'Codapay',
        showIcon: true,
      },
      up_gift_card_express: {
        key: 'up_gift_card_express',
        name: 'up_gift_card_express',
        text: 'UP GIFT CARD Express',
        logo: 'up_gift_card_express.png',
        payment: 'UniPin',
        showIcon: false,
      },
      uni_pin_express: {
        key: 'uni_pin_express',
        name: 'uni_pin_express',
        text: 'UniPin Express',
        logo: 'uni_pin_express.png',
        payment: 'UniPin',
        showIcon: false,
      },
      telkomsel: {
        key: 'telkomsel',
        name: 'telkomsel',
        text: 'Telkomsel',
        logo: 'telkomsel.png',
        payment: 'Codapay',
        showIcon: true,
      },
      indosat: {
        key: 'indosat',
        name: 'indosat',
        text: 'Indosat',
        logo: 'indosat.png',
        payment: 'Codapay',
        showIcon: true,
      },
      tri: {
        key: 'tri',
        name: 'tri',
        text: 'Tri',
        logo: 'tri.png',
        payment: 'Codapay',
        showIcon: true,
      },
      mobile_banking: {
        key: 'mobile_banking',
        name: 'mobile_banking',
        text: 'Mobile Banking',
        logo: 'mobile_banking.png',
        payment: 'Codapay',
        showIcon: true,
      },
      globe: {
        key: 'globe',
        name: 'globe',
        text: 'Globe',
        logo: 'globe.png',
        payment: 'Codapay',
        showIcon: true,
      },
      smart: {
        key: 'smart',
        name: 'smart',
        text: 'Smart',
        logo: 'smart.png',
        payment: 'Codapay',
        showIcon: true,
      },
      sun: {
        key: 'sun',
        name: 'sun',
        text: 'Sun',
        logo: 'sun.jpg',
        payment: 'Codapay',
        showIcon: true,
      },
      bdo: {
        key: 'bdo',
        name: 'bdo',
        text: 'BDO',
        logo: 'bdo.png',
        payment: 'UniPin',
        showIcon: true,
      },
      bpi: {
        key: 'bpi',
        name: 'bpi',
        text: 'BPI',
        logo: 'bpi.jpg',
        payment: 'UniPin',
        showIcon: true,
      },
      ucpb: {
        key: 'ucpb',
        name: 'ucpb',
        text: 'UCPB',
        logo: 'ucpb.png',
        payment: 'UniPin',
        showIcon: true,
      },
      eastwest: {
        key: 'eastwest',
        name: 'eastwest',
        text: 'EastWest',
        logo: 'eastwest.png',
        payment: 'UniPin',
        showIcon: true,
      },
      pnb: {
        key: 'pnb',
        name: 'pnb',
        text: 'PNB',
        logo: 'pnb.png',
        payment: 'Codapay',
        showIcon: true,
      },
      chinabank: {
        key: 'chinabank',
        name: 'chinabank',
        text: 'Chinabank',
        logo: 'chinabank.gif',
        payment: 'UniPin',
        showIcon: true,
      },
      maybank: {
        key: 'maybank',
        name: 'maybank',
        text: 'Maybank',
        logo: 'maybank.png',
        payment: 'UniPin',
        showIcon: true,
      },
      security_bank: {
        key: 'security_bank',
        name: 'security_bank',
        text: 'Security Bank',
        logo: 'security_bank.png',
        payment: 'UniPin',
        showIcon: true,
      },
      sterling_bank: {
        key: 'sterling_bank',
        name: 'sterling_bank',
        text: 'Sterling Bank',
        logo: 'sterling_bank.png',
        payment: 'Codapay',
        showIcon: true,
      },
      g_cash: {
        key: 'g_cash',
        name: 'g_cash',
        text: 'GCash',
        logo: 'g_cash.png',
        payment: 'UniPin',
        showIcon: true,
      },
      '7_eleven': {
        key: '7_eleven',
        name: '7_eleven',
        text: '7-Eleven',
        logo: '7_eleven.png',
        payment: 'UniPin',
        showIcon: true,
      },
      bayad_center: {
        key: 'bayad_center',
        name: 'bayad_center',
        text: 'Bayad Center',
        logo: 'bayad_center.png',
        payment: 'UniPin',
        showIcon: true,
      },
      robinsons: {
        key: 'robinsons',
        name: 'robinsons',
        text: "Robinson's",
        logo: 'robinsons.png',
        payment: 'UniPin',
        showIcon: true,
      },
      maxis: {
        key: 'maxis',
        name: 'maxis',
        text: 'Maxis',
        logo: 'maxis.png',
        payment: 'Codapay',
        showIcon: true,
      },
      digi: {
        key: 'digi',
        name: 'digi',
        text: 'DiGi',
        logo: 'digi.png',
        payment: 'UniPin',
        showIcon: true,
      },
      digi2: {
        key: 'digi2',
        name: 'digi',
        text: 'DiGi',
        logo: 'digi.png',
        payment: 'Codapay',
        showIcon: true,
      },
      celcom: {
        key: 'celcom',
        name: 'celcom',
        text: 'Celcom',
        logo: 'celcom.png',
        payment: 'UniPin',
        showIcon: true,
      },
      mobile_carrier_billing: {
        key: 'mobile_carrier_billing',
        name: 'mobile_carrier_billing',
        text: 'Mobile Payment',
        logo: 'mobile_payment.png',
        payment: 'Razer',
        showIcon: true,
      },
      kk_mart: {
        key: 'kk_mart',
        name: 'kk_mart',
        text: 'kk Mart',
        logo: 'kk_mart.png',
        payment: 'UniPin',
        showIcon: true,
      },
      m1: {
        key: 'm1',
        name: 'm1',
        text: 'M1',
        logo: 'm1.png',
        payment: 'Codapay',
        showIcon: true,
      },
      moip: {
        key: 'moip',
        name: 'moip',
        text: 'Bank Transfer',
        logo: 'moip.png',
        payment: 'Razer',
        showIcon: false,
      },
      paysafecard: {
        key: 'paysafecard',
        name: 'paysafecard',
        text: 'Paysafecard',
        logo: 'paysafecard.png',
        payment: 'Razer',
        showIcon: false,
      },
      safety_pay_online: {
        key: 'safety_pay_online',
        name: 'safety_pay_online',
        text: 'Safety Pay (Online)',
        logo: 'safety_pay.png',
        payment: 'Razer',
        showIcon: false,
      },
      safety_pay_cash: {
        key: 'safety_pay_cash',
        name: 'safety_pay_cash',
        text: 'Safety Pay (Cash)',
        logo: 'safety_pay.png',
        payment: 'Razer',
        showIcon: false,
      },
      pix: {
        key: 'pix',
        name: 'pix',
        text: 'PIX',
        logo: 'pix.png',
        payment: 'Razer',
        showIcon: false,
      },
      express_boleto: {
        key: 'express_boleto',
        name: 'express_boleto',
        text: 'Express Boleto',
        logo: 'express_boleto.png',
        payment: 'Razer',
        showIcon: false,
      },
      pay_zen: {
        key: 'pay_zen',
        name: 'pay_zen',
        text: 'Credit and Debit Cards',
        logo: 'pay_zen.png',
        payment: 'Razer',
        showIcon: false,
      },
      mercado_pago_mexico: {
        key: 'mercado_pago_mexico',
        name: 'mercado_pago_mexico',
        text: 'MercadoPago Mexico',
        logo: 'mercado_pago_mexico.png',
        payment: 'Razer',
        showIcon: false,
      },
      todito_cash_mexico: {
        key: 'todito_cash_mexico',
        name: 'todito_cash_mexico',
        text: 'ToditoCash Mexico',
        logo: 'todito_cash_mexico.png',
        payment: 'Razer',
        showIcon: false,
      },
      pse_colombia: {
        key: 'pse_colombia',
        name: 'pse_colombia',
        text: 'PSE Colombia',
        logo: 'pse_colombia.png',
        payment: 'Razer',
        showIcon: false,
      },
      toss_card: {
        key: 'toss_card',
        name: 'toss_card',
        text: 'Link Pay',
        logo: 'toss.png',
        payment: 'Toss',
        showIcon: true,
      },
      my_card: {
        key: 'my_card', // 主键
        name: 'my_card', // 渠道名称（用于后端传输）
        text: 'MyCard', // 渠道名称展示
        logo: 'my_card.png', // 渠道 logo
        payment: 'MyCard', // 支付方式
        showIcon: false, // 是否显示右上角“支付方式角标”
      },
    },
  },
  getters: {
    getPayment: (state) => (name) => state.payment_lib[name] || null,
    getChannelByNameAndPayment: (state) => (pty, name) =>
      Object.values(state.channel_lib).find(
        (channel) => channel.name === name && channel.payment === pty
      ) || null,
    getChannelByKey: (state) => (key) => state.channel_lib[key] || null,
    getImage: () => (imageName) =>
      imageName ? require('../assets/payments/' + imageName) : '',
  },
};

export default Payments;
