import i18n from '@/locales/index';
import { isTw } from '@/constants/env';
export default {
  namespaced: true,
  getters: {
    countries_lib: () => ({
      GLOBAL: {
        lang: isTw ? 'tw' : 'en',
        name: 'DF',
        full: i18n.t('region.other'),
        icon: '',
        banner: '',
        group: [
          {
            title: i18n.t('recommended_recharge'),
            channels: ['pay_pal'],
          },
          {
            title: i18n.t('choose_region'),
            showCountries: true,
          },
          {
            title: i18n.t('other_region_recharge'),
            channels: ['e_wallet'],
          },
        ],
      },
      ID: {
        lang: 'id',
        name: 'ID',
        full: i18n.t('region.id'),
        icon: 'yn.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.carrier_billing'),
            channels: ['indosat', 'tri', 'telkomsel'],
          },
          {
            title: i18n.t('select_channel["e-wallet"]'),
            channels: ['go_pay', 'e_wallet', 'uni_pin'],
          },
        ],
      },
      MY: {
        lang: 'en',
        name: 'MY',
        full: i18n.t('region.my'),
        icon: 'ml.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.carrier_billing'),
            channels: ['maxis'],
          },
          {
            title: i18n.t('select_channel["e-wallet"]'),
            channels: ['e_wallet', 'uni_pin'],
          },
        ],
      },
      SG: {
        lang: 'en',
        name: 'SG',
        full: i18n.t('region.sg'),
        icon: 'xjp.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.carrier_billing'),
            channels: ['m1'],
          },
          {
            title: i18n.t('select_channel["e-wallet"]'),
            channels: ['e_wallet'],
          },
        ],
      },
      TH: {
        lang: 'th',
        name: 'TH',
        full: i18n.t('region.th'),
        icon: 'tg.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.bank-transfer'),
            channels: ['bank_transfer_and_counter_payment'],
          },
          {
            title: i18n.t('select_channel["e-wallet"]'),
            channels: ['rabbit_Line_Pay', 'e_wallet', 'true_wallet', 'm_pay'],
          },
        ],
      },
      PH: {
        lang: 'en',
        name: 'PH',
        full: i18n.t('region.ph'),
        icon: 'flb.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.carrier_billing'),
            channels: ['globe', 'smart', 'sun'],
          },
          {
            title: i18n.t('select_channel["e-wallet"]'),
            channels: ['e_wallet', 'uni_pin'],
          },
        ],
      },
      BR: {
        lang: 'en',
        name: 'BR',
        full: i18n.t('region.br'),
        icon: 'br.png',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel["e-wallet"]'),
            channels: ['e_wallet', 'pix'],
          },
          {
            title: i18n.t('select_channel.bank-transfer'),
            channels: ['moip'],
          },
          {
            // title: 'Over The Counter',
            title: i18n.t('select_channel.cash'),
            channels: ['express_boleto'],
          },
          {
            title: i18n.t('select_channel.cards'),
            channels: ['pay_zen'],
          },
        ],
      },
      MX: {
        lang: 'en',
        name: 'MX',
        full: i18n.t('region.mx'),
        icon: 'mx.png',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: [
              'e_wallet',
              'mercado_pago_mexico',
              // 'todito_cash_mexico',
            ],
          },
        ],
      },
      CO: {
        lang: 'en',
        name: 'CO',
        full: i18n.t('region.co'),
        icon: 'co.png',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['e_wallet', 'pse_colombia'],
          },
        ],
      },
      AR: {
        lang: 'en',
        name: 'AR',
        full: i18n.t('region.ar'),
        icon: 'ar.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['e_wallet'],
          },
        ],
      },
      CL: {
        lang: 'en',
        name: 'CL',
        full: i18n.t('region.cl'),
        icon: 'cl.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['e_wallet'],
          },
        ],
      },
      PE: {
        lang: 'en',
        name: 'PE',
        full: i18n.t('region.pe'),
        icon: 'pe.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['e_wallet'],
          },
        ],
      },
      KR: {
        lang: 'kr',
        name: 'KR',
        full: i18n.t('region.kr'),
        icon: 'kr.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.credit-card'),
            channels: ['toss_card'],
          },
        ],
      },
      BN: {
        lang: 'en',
        name: 'BN',
        full: i18n.t('region.bn'),
        icon: 'bn.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['uni_pin'],
          },
        ],
      },
      KH: {
        lang: 'en',
        name: 'KH',
        full: i18n.t('region.kh'),
        icon: 'kh.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['uni_pin', 'e_wallet'],
          },
        ],
      },
      MM: {
        lang: 'en',
        name: 'MM',
        full: i18n.t('region.mm'),
        icon: 'mm.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['uni_pin', 'e_wallet'],
          },
        ],
      },
      LA: {
        lang: 'en',
        name: 'LA',
        full: i18n.t('region.la'),
        icon: 'la.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['uni_pin'],
          },
        ],
      },
      JP: {
        lang: 'en',
        name: 'JP',
        full: i18n.t('region.jp'),
        icon: 'jp.gif',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.e-wallet'),
            channels: ['e_wallet'],
          },
        ],
      },
      TW: {
        lang: 'tw',
        name: 'TW',
        full: i18n.t('region.tw_hk_macao'),
        icon: '',
        banner: '',
        group: [
          {
            title: i18n.t('select_channel.top-up-method'),
            channels: ['my_card'],
          },
        ],
      },
    }),
    getCountries: (state, getters) => (countries) =>
      countries.map((country) => getters.countries_lib[country]),
    getCountry: (state, getters) => (country) => {
      let l = JSON.parse(JSON.stringify(getters.countries_lib[country]));
      if (!l) return null;
      if (country !== 'GLOBAL' && !isTw) {
        l.group.unshift(getters.countries_lib.GLOBAL.group[0]);
      }
      return l;
    },
    getImage: () => (imageName) =>
      imageName ? require('../assets/countries/' + imageName) : '',
  },
};
