<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>
<style lang="less" scoped>
#app {
  min-height: 100%;
  position: relative;
}
#main {
  flex-grow: 1;
}
</style>
