import axios from 'axios';
import i18n from '../locales';
import { getOS } from '@/utils/browser';
// const API_BASE_URL = 'https://xdg-hk.xd.com'
// const API_BASE_URL = 'https://tds-dev.xindong.com:443'

// let API_BASE_URL = 'https://xdg-hk.xd.com'
// 'https://tds-dev.xindong.com';

// VUE_APP_API_BASE_URL = https://tds-dev.xindong.com:443
let API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

const host = window.location.host;
// if (host.indexOf('pay-sausageman.starforce.tw') > -1) {
//     API_BASE_URL = 'https://pay-sausageman.starforce.tw';
// } else
if (
  host.indexOf('pay-sausageman.xd.com') > -1 ||
  host.indexOf('pay-sausageman-advance') > -1 ||
  host.indexOf('47.241.20.31:8084') > -1 ||
  // host.indexOf('pay-sausageman-test') > -1 ||
  host.indexOf('47.241.20.31:8082') > -1 ||
  host.indexOf('pay-sausageman.starforce.tw') > -1
) {
  API_BASE_URL = 'https://xdg-hk.xd.com';
}

function successHandle(res) {
  if (res.data && res.data.success) {
    return res.data;
  } else {
    throw new Error('-1');
  }
}
function errorHandle(error) {
  let msg = 'error.loadingError';
  if (!error.response && error.message !== '-1') {
    msg = 'error.networkError';
  }
  throw i18n.t(msg);
}

function get_data(url, params, receive = successHandle, reject = errorHandle) {
  return axios
    .get(url, {
      params,
    })
    .then(receive)
    .catch(reject);
}
function post_data(url, params, receive = successHandle, reject = errorHandle) {
  return axios.post(url, params).then(receive).catch(reject);
}

// 获取商品列表
function get_product({ client_id, country, channel, payment_type }) {
  if (!country) country = 'DF';
  return get_data(API_BASE_URL + '/api/v1/pay/web/product_list', {
    client_id,
    country,
    channel,
    payment_type,
  });
}
// 根据国家和商品ID获取商品列表
function get_product_by_id({ client_id, country, product_id }) {
  if (!country) country = 'DF';
  return get_data(API_BASE_URL + '/api/v1/pay/web/product', {
    client_id,
    country,
    product_id,
  });
}

function get_role(params) {
  return get_data(API_BASE_URL + '/api/v1/user/get_role', params);
}

function create_order({ pay_channel, ...params }) {
  let url = `${API_BASE_URL}/api/v1/pay/web/create_order?pt=${getOS()}`;
  if (pay_channel) url += `&pay_channel=${pay_channel}`;
  return post_data(url, params);
}

/* 获取ip地址 */
function get_ip() {
  let request = new Request('https://poster-api.xd.com/api/v1.0/common/ip', {
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=utf-8;',
    },
  });
  return fetch(request).then((res) => res.json());
}

export { get_product, get_product_by_id, get_role, create_order, get_ip };
