import Vue from 'vue';
import VueRouter from 'vue-router';
import Payment from '../views/Payment.vue';
import Order from '../views/Order.vue';
import Sdk from '../views/Sdk.vue';
import SdkPayment from '../views/SdkPayment.vue';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  { name: 'index', path: '/', component: Payment },
  {
    name: 'dpurchase',
    path: '/dpurchase',
    component: Payment,
    beforeEnter: (to, from, next) => {
      const { country, ...others } = to.query;
      if (!store.getters.isCountryValid(country)) {
        next({ name: 'index', query: { ...others } });
      } else next();
    },
  },
  {
    name: 'order',
    path: '/order',
    component: Order,
    beforeEnter: (to, from, next) => {
      const { country, channel, payment_type, ...others } = to.query;
      if (!store.getters.isChannelValid(country, payment_type, channel)) {
        next({ name: 'index', query: { ...others } });
      } else next();
    },
  },
  {
    name: 'sdk',
    path: '/sdk',
    component: Sdk,
  },
  {
    name: 'sdkPayment',
    path: '/sdkpayment',
    component: SdkPayment,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
