<template>
  <a-spin :spinning="loading" :delay="500">
    <div class="order-sdk">
      <section class="header">
        <span class="product-name">
          <b>{{ product_desc }}</b>
        </span>
        <a-dropdown placement="bottomRight" :trigger="['click']">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            {{ country_name }}
            <a-icon type="down" />
          </a>
          <a-menu
            slot="overlay"
            style="width: 300px; max-height: 100vh; overflow: scroll"
            :selected-keys="[country]"
            @click="({ key }) => updateCountry(key)"
          >
            <a-menu-item v-for="item in country_list" :key="item.name">
              <a href="javascript:;">{{ item.full }}</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </section>
      <section class="body" :class="{ 'with-error': !groups.length }">
        <div v-for="(group, index) in groups" :key="index" class="group">
          <div class="title">{{ group.title }}:</div>
          <div
            v-for="(item, i) in group.contents"
            :id="item.channel + '_' + item.payment_type"
            :key="i"
            class="item"
          >
            <Channel
              :logo="item.banner"
              :icon="item.icon"
              :name="item.text"
              :price="item.amount"
              :currency="item.currency"
              :checked="
                item.channel === channel && item.payment_type === payment_type
              "
              @click="updateChannel(item)"
            />
          </div>
        </div>
        <span v-if="!groups.length && groups_error" style="color: #5c5c5c">{{
          groups_error
        }}</span>
      </section>
      <section class="footer">
        <a v-if="groups.length" class="btn-submit" @click="submitOrder">
          {{ $i18n.t('sdk.submit_order') }}
        </a>
      </section>
    </div>
  </a-spin>
</template>
<script>
import { mapGetters } from 'vuex';
import { get_product_by_id } from '@/api';
import Channel from '@/components/Channel';
import { Modal } from 'ant-design-vue';
import { setDefaultLanguage } from '@/locales/index';

export default {
  components: { Channel },
  data: function () {
    let { client_id, product_id, product_desc, lang, channel, payment_type } =
      this.$route.query;
    return {
      client_id,
      product_id,
      product_desc,
      country: '',
      lang,
      channel,
      payment_type,
      product_key: '',
      price_list: [],
      groups: [],
      loading: false,
      groups_error: '',
    };
  },
  computed: {
    ...mapGetters(['getCountryByName', 'getGroups', 'area']),
    ...mapGetters('countries', ['getCountries']),
    country_list() {
      let { area, getCountries } = this;
      let countries = [...area.countries, 'GLOBAL'];
      return getCountries(countries);
    },
    country_name() {
      let country = this.getCountryByName(this.country);
      return country.full;
    },
  },
  async mounted() {
    let { country, lang = 'en', channel, payment_type } = this.$route.query;
    setDefaultLanguage(lang);
    await this.updateCountry(country);
    if (!this.groups.length) return;
    if (channel === this.channel && payment_type === this.payment_type) {
      let el = document.getElementById(channel + '_' + payment_type);
      if (el) el.scrollIntoView();
    }
  },
  methods: {
    updateGroups() {
      const { country, price_list, channel, payment_type } = this;
      let result = [];
      let checked = null;
      if (!country || !price_list.length) return result;
      const arr = [...this.getGroups(country)];
      result = arr.reduce((prev, { contents, title }) => {
        let _r = contents.reduce((acc, { query, ...other }) => {
          let _o = price_list.find(
            (obj) =>
              obj.channel === query.channel &&
              obj.payment_type === query.payment_type
          );
          if (_o) {
            _o = { ...other, ..._o };
            if (
              channel &&
              payment_type &&
              _o.channel === channel &&
              _o.payment_type === payment_type
            ) {
              checked = { ..._o };
            }

            acc.push(_o);
          }
          return acc;
        }, []);
        if (_r.length) prev.push({ title, contents: _r });
        return prev;
      }, []);
      if (!result.length) return result;
      if (!checked) checked = result[0].contents[0];

      this.updateChannel(checked);

      return result;
    },
    async getProducts() {
      let { client_id, product_id, country } = this;
      if (!country || !client_id || !product_id) return;

      try {
        this.groups_error = '';
        this.loading = true;
        let result = await get_product_by_id({
          country,
          client_id,
          product_id,
        });
        if (result.success) {
          this.price_list = result.data;
          this.groups = this.updateGroups();
          if (!this.groups.length) throw new Error(this.$t('sdk.emptyProduct'));
        } else {
          throw new Error(this.$t('loadingError'));
        }
      } catch (error) {
        this.price_list = [];
        this.groups = [];
        this.groups_error = error?.message || error;
      } finally {
        this.loading = false;
      }
    },
    async updateCountry(country) {
      if (!country) {
        if (!this.country_list.length) return;
        country = this.country_list[0].name;
      }

      let _v = this.getCountryByName(country);
      if (!_v) return;

      // this.country = country;
      this.country = _v.name;
      this.$router
        .replace({
          query: { ...this.$route.query, country: _v.name },
        })
        .catch(() => {});
      // this.$route.query.country = _v.name;
      await this.getProducts();
    },
    updateChannel(item) {
      if (!item) return;
      let { channel, payment_type, product_key } = item;
      this.channel = channel;
      this.payment_type = payment_type;
      this.product_key = product_key;
    },
    submitOrder() {
      const { product_key, country, channel, payment_type } = this;
      if (!product_key || !country || !channel || !payment_type) return;
      let data = JSON.stringify({
        product_key,
        country,
        channel,
        payment_type,
      });
      try {
        this.loading = true;
        window.XDInlineWebPay.submitOrder(data);
      } catch (error) {
        Modal.error({
          title: this.$t('loadingError'),
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.order-sdk {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #000;
  > .header,
  > .footer {
    flex-shrink: 0;
    flex-grow: 0;
  }
  > .header {
    height: 40px;
    border-bottom: 1px solid #e6e6e6;
    padding: 0 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  > .body {
    overflow: scroll;
    flex-grow: 1;
    > .group {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 14px 6px;
      > .title {
        width: 100%;
        padding: 14px 10px 0;
        line-height: 14px;
      }
      > .item {
        width: 50%;
        padding: 14px 10px 0;
      }
    }
    &.with-error {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
  > .footer {
    height: 72px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > .btn-submit {
      display: block;
      width: 258px;
      height: 32px;
      line-height: 32px;
      flex-shrink: 0;
      border-radius: 8px;
      box-shadow: inset 0px -2px 0px #009baa;
      text-align: center;
      background-color: #00b9c8;
      color: white;
    }
  }
}
</style>
