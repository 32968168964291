<template>
  <router-link class="banner" :class="{ 'banner-starforce': isTw }" to="/">
    <SelectLanguage class="choose-language" />
  </router-link>
</template>

<script>
import { isTw } from '@/constants/env';
import SelectLanguage from '@/components/SelectLanguage/index.vue';
export default {
  name: 'Header',
  components: {
    SelectLanguage,
  },
  data() {
    return {
      isTw,
    };
  },
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  height: 220px;
  background: url('../assets/bg-banner.png') center center no-repeat;
  display: block;
  background-size: auto 100%;
  position: relative;
  &&-starforce {
    background-image: url('../assets/starforce/bg-banner.png');
  }
  .choose-language {
    position: absolute;
    right: 100px;
    top: 10px;
    @media (max-width: 1200px) {
      right: 90px;
      top: 10px;
    }
    @media (min-width: 1920px) {
      right: calc((100% - 1920px) / 2 + 100px);
    }
  }
}
@media (max-width: 960px) {
  .banner {
    height: 110px;
    background-position: center center;
  }
}
</style>
