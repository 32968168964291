import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en.json';
import th from './th.json';
import id from './id.json';
import ja from './ja.json';
import tw from './tw.json';
import es from './es.json';
import pt from './pt.json';
import kr from './kr.json';
import vi_VN from './vi_VN.json';
import ru from './ru.json';
import de from './de.json';
import fr from './fr.json';
import tr from './tr.json';

import { isTw } from '@/constants/env';
import { getCookieLang, setCookie } from '@/libs/index';
Vue.use(VueI18n);
export const defaultLocale = isTw ? 'tw' : '';

const i18n = new VueI18n({
  locale: getCookieLang() || defaultLocale,
  messages: {
    en, // 英文语言包
    th, // 泰文语言包
    id, // 印尼语言包
    ja, // 日文语言包
    tw, // 繁体中文语言包
    es, // 西班牙语言包
    pt, // 葡萄牙语言包
    kr, // 韩语语言包
    vi_VN, // 越南语言包
    ru, // 俄语语言包
    de, // 德语语言包
    fr, // 法语语言包
    tr, // 土耳其语语言包
  },
});

export const localeList = [
  'en',
  'th',
  'id',
  'ja',
  'tw',
  'es',
  'pt',
  'kr',
  'vi_VN',
  'ru',
  'de',
  'fr',
  'tr',
];

export const setDefaultLanguage = async (lang) => {
  setCookie('lang-set', lang);
  if (i18n.locale !== lang) {
    i18n.locale = lang;
  }
};

export default i18n;
