import Vue from 'vue';
import Vuex from 'vuex';
import payments from './payments';
import countries from './countries';
import { isTw } from '@/constants/env';

import { get_product } from '../api';
const isProd =
  ['pay-sausageman.xd.com', 'pay-sausageman.starforce.tw'].indexOf(
    window.location.host
  ) > -1;

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    payments,
    countries,
  },
  state: {
    // 正式版：pay-sausageman.xd.com
    // 测试站：http://47.241.20.31:8082/
    env: isProd ? 'prod' : 'dev',
    areas: {
      dev: {
        GLOBAL: {
          id: 'M4Jq3mtgc5UW9ikp',
          sids: [{ sid: 'global-test', name: 'global-test' }],
          countries: [
            'SG',
            'ID',
            'MY',
            'TH',
            'PH',
            'BR',
            'MX',
            'CO',
            'AR',
            'CL',
            'PE',
            'KR',
            'BN',
            'KH',
            'MM',
            'LA',
            'TW',
            'JP',
          ],
          site: 'https://sausageman.xd.com', //官网
          TW: {
            id: 'M4Jq3mtgc5UW9ikp',
            sids: [{ sid: 'global-test', name: 'global-test' }],
            countries: ['TW'],
            site: 'https://pay-sausageman.starforce.tw/tw',
          },
        },
      },
      prod: {
        GLOBAL: {
          id: isTw ? 'qIY5ZBalVoDVoOQK' : 'zuRsHFfcY2KtVql3',
          sids: [{ sid: 'global-release', name: 'global-release' }],
          countries: [
            'SG',
            'ID',
            'MY',
            'TH',
            'PH',
            'BR',
            'MX',
            'CO',
            'AR',
            'CL',
            'PE',
            'KR',
            'BN',
            'KH',
            'MM',
            'LA',
            'TW',
            'JP',
          ],
          site: 'https://sausageman.xd.com', //官网
          TW: {
            id: isTw ? 'qIY5ZBalVoDVoOQK' : 'zuRsHFfcY2KtVql3',
            sids: [{ sid: 'global-release', name: 'global-release' }],
            countries: ['TW'],
            site: 'https://pay-sausageman.starforce.tw/tw',
          },
        },
      },
    },
    products: {},
  },
  getters: {
    area_name: () => 'GLOBAL',
    area: (state, getters) => state.areas[state.env][getters.area_name],
    client_id: (state, getters) => getters.area.id,
    site: (state, getters) => getters.area.site,
    country_list: (state, getters) =>
      getters['countries/getCountries'](getters.area.countries),
    servers: (state, getters) => getters.area.sids,
    // 默认国家名称为default时，对应的key
    getCountryKey: (state, getters) => (name) =>
      !name || name === 'DF' || !getters.isCountryValid(name)
        ? getters.area_name
        : name,
    // 检测国家是否有效，注意：默认国家无效
    isCountryValid: (state, getters) => (name) =>
      getters.area.countries.indexOf(name) > -1,
    isChannelValid: (state, getters) => (country_name, pty, ch) => {
      if (!ch) return false;
      let key = getters.getCountryKey(country_name);
      if (country_name && country_name !== 'DF' && country_name !== key)
        return false;
      let channel = getters['payments/getChannelByNameAndPayment'](pty, ch);
      if (!channel) return false;
      let ck = channel.key;

      let { group } = getters['countries/getCountry'](key);
      let result = group.find(
        ({ channels }) => channels && channels.indexOf(ck) > -1
      );
      return !!result;
    },
    getCountryByName: (state, getters) => (key) => {
      let name = getters.isCountryValid(key) ? key : getters.area_name;
      return getters['countries/getCountry'](name);
    },
    getGroups: (state, getters) => (name) => {
      let country = getters.getCountryByName(name);

      return country.group.map((obj) => {
        let tmp = { title: obj.title, contents: [] };
        let query = { channel: '' };
        if (obj.showCountries) {
          tmp.contents = getters.country_list.map((c) => {
            return {
              isCountry: true,
              text: c.full,
              banner: '',
              icon: getters['countries/getImage'](c.icon),
              // 按国家展示充值渠道时，渠道及方式参数重置为空，设置国家
              query: {
                ...query,
                country: c.name,
              },
            };
          });
        } else {
          tmp.contents = obj.channels.map((k) => {
            let channel = getters['payments/getChannelByKey'](k);
            let ico = '';
            if (channel.showIcon) {
              let payment = getters['payments/getPayment'](channel.payment);
              if (payment) ico = getters['payments/getImage'](payment.icon);
            }
            return {
              isCountry: false,
              text: channel.text,
              banner: getters['payments/getImage'](channel.logo),
              icon: ico,
              // 选择渠道时，渠道及方式设置为相应的值
              query: {
                ...query,
                channel: channel.name,
                country: country.name,
                payment_type: channel.payment,
              },
            };
          });
        }
        return tmp;
      });
    },
    getProductKey: (state, getters) => (country, payment_type, channel) =>
      [
        getters.client_id,
        getters.getCountryKey(country),
        payment_type,
        channel,
      ].join('_'),
    getProduct:
      (state, getters) =>
      (country = '', payment_type = '', channel = '') => {
        let key = getters.getProductKey(country, payment_type, channel);
        return state.products[key] || null;
      },
  },
  mutations: {
    addProduct(state, payload) {
      state.products = { ...state.products, ...payload };
    },
  },
  actions: {
    async asyncAddProduct(
      { commit, getters },
      { country = '', payment_type, channel = '' }
    ) {
      if (!getters.isChannelValid(country, payment_type, channel)) return;
      let key = getters.getProductKey(country, payment_type, channel);

      let result = await get_product({
        client_id: getters.client_id,
        country,
        channel,
        payment_type,
      });
      if (result.success) {
        commit('addProduct', {
          [key]: result.data.product_list,
        });
      } else {
        throw new Error(result.msg);
      }
    },
  },
});
export default store;
