function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}
export default getCookie;
export const getRegion = () => getCookie('xd-region');
export const getCookieLang = () => getCookie('lang-set');
export const setCookie = (key, value) => {
  document.cookie = `${key}=${value}`;
};
