import { render, staticRenderFns } from "./Channel.vue?vue&type=template&id=4aaf55dc&scoped=true&"
import script from "./Channel.vue?vue&type=script&lang=js&"
export * from "./Channel.vue?vue&type=script&lang=js&"
import style0 from "./Channel.vue?vue&type=style&index=0&id=4aaf55dc&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_j7knk6mol3vthgvrc443rvn57u/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aaf55dc",
  null
  
)

export default component.exports