// 语言列表

export const LanguageList = {
  en: 'English',
  th: 'ไทย',
  id: 'Indonesia',
  ja: '日本語',
  tw: '繁體中文',
  es: 'Español',
  pt: 'Português',
  kr: '한국어',
  vi_VN: 'Tiếng Việt',
  ru: 'Русский',
  de: 'Deutsch',
  fr: 'Français',
  tr: 'Türkçe',
};
