<template>
  <a
    class="banner"
    :class="{ 'banner-country': content.isCountry }"
    @click.stop.prevent="$emit('handle-click')"
  >
    <div
      v-if="!content.isCountry"
      class="logo"
      :style="{ backgroundImage: 'url(' + content.banner + ')' }"
    ></div>
    <span v-if="!content.isCountry" class="divide"></span>
    <span class="text">{{ content.text }}</span>
    <img
      v-if="!content.isCountry && content.icon"
      class="icon-payment"
      :src="content.icon"
      :alt="content.text"
    />
    <!-- <img
            v-if="content.isCountry"
            class="icon-country"
            :src="content.icon"
            :alt="content.text"
        /> -->
  </a>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.banner {
  position: relative;
  display: flex;
  width: 370px;
  height: 50px;
  padding: 1px;
  border: 1px solid #bebebe;
  border-radius: 5px;
  justify-content: flex-start;
  align-items: center;
  .logo {
    margin: 0 5px;
    width: 58px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
  .divide {
    width: 1px;
    height: 20px;
    background-color: #c4c4c4;
  }
  .text {
    color: #5e5e5e;
    font-size: 14px;
    margin-left: 10px;
  }
  .icon-payment {
    position: absolute;
    height: 10px;
    top: 4px;
    right: 9px;
  }
  &:hover {
    border-width: 2px;
    border-color: #61d4db;
    padding: 0;
    .icon-payment {
      top: 3px;
      right: 8px;
    }
  }
  &.banner-country {
    justify-content: space-between;
    .text {
      margin-left: 14px;
    }
    .icon-country {
      height: 15px;
      border: 1px solid #bebebe;
      border-radius: 2px;
      margin-right: 14px;
    }
  }
}

@media (max-width: 850px) {
  .banner {
    width: 100%;
  }
}
</style>
