<template>
  <div class="channel" :class="{ checked }" @click="$emit('click')">
    <div class="detail">
      <div
        class="logo"
        :style="{ backgroundImage: logo && `url(${logo})` }"
      ></div>
      <div class="divide"></div>
      <div class="name">{{ name }}</div>
      <img v-if="icon" class="icon" :src="icon" />
    </div>
    <div class="currency">
      {{ price }}
      {{ currency }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    checked: Boolean,
    logo: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    price: {
      type: [String, Number],
      default: '',
    },
    currency: {
      type: [String, Number],
      default: '',
    },
  },
};
</script>
<style lang="less" scoped>
.channel {
  height: 56px;
  border: 2px solid #e6e6e6;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  color: inherit;
  > .detail {
    display: flex;
    position: relative;
    flex-grow: 1;
    align-items: center;
    padding: 0 8px;
    > .logo {
      width: 66px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
    > .divide {
      height: 20px;
      width: 1px;
      margin: 0 10px;
      background-color: #bebebe;
    }
    > .name {
      height: 20px;
      line-height: 20px;
      color: #5c5c5c;
    }
    > .icon {
      position: absolute;
      right: 8px;
      top: 4px;
      height: 10px;
    }
  }
  > .currency {
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;
    height: 18px;
    margin: 0 2px 2px;
    padding: 0 6px;
    line-height: 18px;
    text-align: right;
    font-weight: bold;
    background-color: rgba(190, 190, 190, 0.1);
  }
  &.checked {
    border-color: #00b9c8;
    > .currency {
      color: #00b9c8;
      background-color: rgba(0, 185, 200, 0.1);
    }
  }
}
</style>
