<template>
  <div class="order">
    <Header></Header>
    <div class="payment-type">
      <router-link to="/">{{ $t('payment_type') }}</router-link
      >: {{ payment_type }}
    </div>

    <a-spin :spinning="spinning" :delay="300">
      <a-form-model
        ref="orderForm"
        layout="vertical"
        :model="form"
        :rules="rules"
        class="order-form"
      >
        <a-form-model-item v-if="showSpecialTip">
          <a-alert type="warning" show-icon :message="$t('specialTip.title')">
            <div slot="description" class="ant-alert-description">
              <p>{{ $t('specialTip.message') }}</p>
              <p>Link: <br />{{ link }}</p>
              <a-button
                v-clipboard:copy="link"
                v-clipboard:success="onCopySuccess"
                v-clipboard:error="onCopyError"
                type="primary"
              >
                {{ $t('copyTip.button') }}
              </a-button>
            </div>
          </a-alert>
        </a-form-model-item>
        <a-form-model-item :label="$t('orderForm.currency')">
          <a-select
            v-model="form.game_currency"
            :disabled="!game_currencies.length"
            size="large"
            @change="
              form.product_key = products_filtered.length
                ? products_filtered[0].product_key
                : ''
            "
          >
            <a-select-option
              v-for="currency in game_currencies"
              :key="currency"
              :value="currency"
            >
              {{ currency }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <div class="order-character-id">
          <a-form-model-item
            :label="$t('orderForm.characterID')"
            prop="character_id"
            has-feedback
          >
            <a-input
              v-model="form.character_id"
              size="large"
              :placeholder="$t('orderForm.characterTip')"
              @change="form.character_name = ''"
            />
          </a-form-model-item>
          <div class="cid-tip">
            <div class="tip">
              <img src="../assets/tips.jpeg" class="img" />
            </div>
          </div>
        </div>

        <a-form-model-item
          :label="$t('orderForm.characterName')"
          prop="character_name"
        >
          <a-input :value="form.character_name" disabled size="large" />
        </a-form-model-item>

        <a-form-model-item :label="$t('orderForm.amount')">
          <a-select
            v-model="form.product_key"
            :disabled="!products_filtered.length"
            size="large"
          >
            <a-select-option
              v-for="product in products_filtered"
              :key="product.product_key"
              :value="product.product_key"
            >
              {{ product.amount }}
              {{ product.currency }}
              {{ product.product_desc }}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <p>{{ $t('orderForm.get') }}</p>
        <p style="font-weight: bold; color: red">
          {{ product_selected.product_desc }}
        </p>

        <a-form-model-item>
          <a-checkbox :checked="form.agree" @change="handleChangeAgree">
            {{ $t('orderForm.agree') }}
            <a class="agree-link" target="_blank" :href="agreeLink">{{
              $t('orderForm.agreement')
            }}</a>
          </a-checkbox>
        </a-form-model-item>

        <a-form-model-item>
          <a-button
            class="btn-submit"
            type="danger"
            size="large"
            :disabled="!form.character_name || !form.agree"
            block
            @click="submitOrder"
          >
            {{ $t('recharge') }}
          </a-button>
        </a-form-model-item>
      </a-form-model>
    </a-spin>
    <Footer></Footer>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { message } from 'ant-design-vue';
import { get_role, create_order } from '../api';
import { Modal } from 'ant-design-vue';
import Header from './Header.vue';
import Footer from './Footer.vue';
import { isTw } from '@/constants/env';

export default {
  name: 'Order',
  components: {
    Header,
    Footer,
  },
  data() {
    let checkCharacterID = async (rule, value, callback) => {
      try {
        if (value === '') {
          throw this.$t('orderForm.characterTip');
        }
        let { success, data } = await get_role({
          client_id: this.client_id,
          server_id: this.form.server_id,
          character_id: value,
        });
        // 丢弃延时收到的回应
        if (this.form.character_id !== value) return;
        if (!success || !data) {
          throw this.$t('orderForm.characterTip');
        }
        this.form.character_name = data.name;
        await callback();
      } catch (error) {
        // 丢弃延时收到的回应
        if (this.form.character_id !== value) return;
        await callback(error);
      }
    };
    return {
      form: {
        server_id: undefined,
        game_currency: undefined,
        product_key: undefined,
        agree: true,
        character_id: '',
        character_name: '',
      },
      rules: {
        character_id: [
          {
            type: 'string',
            trigger: 'blur',
            validator: checkCharacterID,
          },
        ],
      },
      spinning: true,
      link: window.location.href,
      isMobile: navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ),
    };
  },
  computed: {
    ...mapGetters(['servers', 'client_id', 'site']),
    payment_type() {
      return this.$route.query.payment_type;
    },
    channel() {
      return this.$route.query.channel;
    },
    country() {
      return this.$route.query.country;
    },
    products() {
      let data = this.$store.getters.getProduct(
        this.country,
        this.payment_type,
        this.channel
      );
      return data;
    },
    game_currencies() {
      let result = [];
      if (this.products) {
        this.products.forEach(({ game_currency }) => {
          if (result.indexOf(game_currency) < 0) {
            result.push(game_currency);
          }
        });
      }
      return result;
    },
    products_filtered() {
      return this.products && this.form.game_currency
        ? this.products.filter(
            ({ game_currency }) => game_currency === this.form.game_currency
          )
        : [];
    },
    product_selected() {
      return this.form.product_key
        ? this.products_filtered.find(
            (product) => product.product_key === this.form.product_key
          )
        : {};
    },
    showSpecialTip() {
      return (
        this.isMobile &&
        this.$t('specialTip') &&
        this.channel === this.$t('specialTip.channel')
      );
    },
    agreeLink() {
      return isTw
        ? 'https://pay-sausageman.starforce.tw/user-agreement'
        : this.country === 'KR'
        ? 'https://website.xdcdn.net/homepage/sausageman-2.0-vue2/static-files/ko/user-agreement.html'
        : 'https://website.xdcdn.net/homepage/sausageman-2.0-vue2/static-files/en/user-agreement.html';
    },
  },
  mounted: async function () {
    try {
      const { country, channel, payment_type } = this;
      if (!this.products) {
        await this.$store.dispatch('asyncAddProduct', {
          country,
          payment_type,
          channel,
        });
      }
      if (!this.products.length) {
        throw new Error('orderForm.emptyProduct');
      }
      this.setSpinning(false);
      this.initFormData();
    } catch (error) {
      this.fetchProductError(error);
    }
  },
  methods: {
    onCopySuccess() {
      message.success(this.$t('copyTip.success'));
    },
    onCopyError() {
      message.error(this.$t('copyTip.failed'));
    },
    setSpinning(flag) {
      this.spinning = !!flag;
    },
    hasHistory() {
      return window.history.length > 2;
    },
    initFormData() {
      const { server_id, role_id } = this.$route.query;
      if (server_id) {
        this.form.server_id = server_id;
      } else {
        this.form.server_id = this.servers[0].sid;
      }
      this.form.game_currency = this.game_currencies[0];
      this.form.product_key = (this.products_filtered[0] || {}).product_key;

      if (role_id) {
        this.form.character_id = role_id;
        this.$refs.orderForm.validateField('character_id');
      }
    },
    handleChangeAgree(e) {
      this.form.agree = e.target.checked;
    },
    fetchProductError(msg) {
      Modal.error({
        title: this.$t(msg),
        // okText: this.$t('goHome'),
        // okType: 'default',
        onOk: () => {
          Modal.destroyAll();
          if (this.hasHistory) {
            this.$router.go(-1);
          } else {
            let { channel, payment_type, country, ...other } =
              this.$route.query;
            this.$router.replace({ name: 'index', query: other });
          }
        },
      });
    },
    submitError(msg) {
      Modal.error({
        title: this.$t(msg),
      });
    },
    async submitOrder() {
      this.setSpinning(true);
      const { character_id, product_key, server_id } = this.form;
      const { client_id, country, channel, payment_type } = this;
      const { pay_channel } = this.$route.query;
      try {
        const params = {
          product_key,
          character_id,
          server_id,
          client_id,
          country,
          channel,
          payment_type,
        };
        if (pay_channel) params.pay_channel = pay_channel;

        let { success, data } = await create_order(params);
        if (success && data.url) {
          window.location.href = data.url;
        } else {
          throw this.$t('loadingError');
        }
      } catch (error) {
        this.setSpinning(false);
        this.submitError(error);
      }
    },
  },
};
</script>

<style lang="less">
.order {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .payment-type {
    background-color: #f4f4f4;
    font-size: 24px;
    text-align: center;
    line-height: 56px;
    color: #454545;
  }
  .ant-spin-nested-loading {
    flex-grow: 1;
  }
  .order-form {
    max-width: 820px;
    margin: 60px auto 60px;
    label,
    > p {
      font-size: 16px;
    }
    .ant-form-item,
    .order-character-id {
      max-width: 592px;
    }
    .order-character-id {
      position: relative;
    }
    .cid-tip {
      position: absolute;
      width: 30px;
      height: 30px;
      background-image: url(../assets/icon_tip.png);
      background-size: 100%;
      right: -40px;
      top: 33px;
      .tip {
        top: 0px;
        position: absolute;
        height: 0;
        font-size: 12px;
        width: 300px;
        right: auto;
        overflow: hidden;
        z-index: 1;
        .img {
          width: 100%;
        }
      }
      &:hover {
        .tip {
          height: 448px;
          transition: 0.5s ease-in-out;
        }
      }
    }
    .order-tips {
      font-size: 12px;
      color: red;
      margin-bottom: 24px;
    }
    .btn-submit {
      max-width: 268px;
    }
  }
  .spin-content {
    border: 1px solid #91d5ff;
    background-color: #e6f7ff;
    padding: 30px;
  }
}
@media (max-width: 960px) {
  .order {
    .order-form {
      .cid-tip {
        .tip {
          right: 0;
        }
      }
    }
  }
}
@media (max-width: 850px) {
  .order {
    .order-form {
      padding: 0 24px;
      max-width: 640px;
      label,
      > p {
        font-size: 14px;
      }
      .order-character-id {
        display: flex;
        align-items: center;
        .ant-form-item {
          flex-grow: 1;
        }
        .cid-tip {
          position: relative;
          margin: 0;
          right: auto;
          top: auto;
          margin-left: 10px;
          flex-shrink: 0;
        }
      }
      .btn-submit {
        max-width: 640px;
      }
    }
  }
}
</style>
