export function getOS() {
  const userAgent = window.navigator.userAgent;
  let os = '';

  if (/iPhone|iPad|iPod|iPad/i.test(userAgent)) {
    os = 'iOS';
  } else if (userAgent.toUpperCase().indexOf('MAC') > -1) {
    os = 'Mac';
  } else if (userAgent.toUpperCase().indexOf('WIN') > -1) {
    os = 'Windows';
  } else if (/android/i.test(userAgent)) {
    os = 'Android';
  }

  return os || 'Web';
}
